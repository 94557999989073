import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../../../components/PageContentLayout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Nesting Animators'
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Nesting Animators`}</h1>
    <p>{`Animator components can be nested to create variations of animation flow transitions.
By default, a child node will listen to its parent node for flow changes
to transition itself.`}</p>
    <p>{`A child node will enter the animation flow when its parent flow is `}<inlineCode parentName="p">{`entered`}</inlineCode>{`.
It will exit the animation flow when its parent flow is `}<inlineCode parentName="p">{`exiting`}</inlineCode>{`.`}</p>
    <p>{`By default, all direct children nodes of a parent node will enter the animation
flow in `}<inlineCode parentName="p">{`parallel`}</inlineCode>{`. This behaviour can be changed by defining an `}<a parentName="p" {...{
        "href": "/develop/animation/managers"
      }}>{`animator manager`}</a>{`.`}</p>
    <p>{`An animated `}<inlineCode parentName="p">{`<Menu/>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<Item/>`}</inlineCode>{` components could be nested like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`<Menu>
  <Item>Menu Item 1</Item>
  <Item>Menu Item 2</Item>
  <Item>Menu Item 3</Item>
</Menu>
`}</code></pre>
    <p>{`All the item nodes will transition to `}<inlineCode parentName="p">{`entering`}</inlineCode>{` at once when the menu node is
on flow `}<inlineCode parentName="p">{`entered`}</inlineCode>{`, unless configured otherwise.`}</p>
    <p>{`A complete example could look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-arwes_sandbox"
      }}>{`// MENU

const menuStyle = {
  listStyle: 'none',
  border: '1px solid cyan',
  padding: '20px 40px',
  background: 'MidnightBlue',

  // Animation initial values.
  opacity: 0,
  translateX: -20
};

const MenuComponent = ({ animator, children }) => {
  const ref = React.useRef();
  animator.setupAnimateRefs(ref);
  return <ul ref={ref} style={menuStyle}>{children}</ul>;
};

const menuAnimator = {
  duration: { enter: 200, exit: 200 },

  onAnimateEntering (animator, ref) {
    anime({
      targets: ref.current,
      duration: animator.duration.enter,
      easing: 'linear',
      opacity: [0, 1],
      translateX: [-20, 0]
    });
  },

  onAnimateExiting (animator, ref) {
    anime({
      targets: ref.current,
      duration: animator.duration.enter,
      easing: 'linear',
      opacity: [1, 0],
      translateX: [0, 20]
    });
  }
};

const Menu = withAnimator(menuAnimator)(MenuComponent);

// ITEM

const itemStyle = {
  display: 'block',
  fontSize: 20,
  color: 'cyan',

  // Animation initial values.
  opacity: 0,
  translateY: -10
};

const ItemComponent = ({ animator, children }) => {
  const ref = React.useRef();
  animator.setupAnimateRefs(ref);
  return <li ref={ref} style={itemStyle}>{children}</li>;
};

const itemAnimator = {
  duration: { enter: 200, exit: 200 },

  onAnimateEntering (animator, ref) {
    anime({
      targets: ref.current,
      duration: animator.duration.enter,
      easing: 'linear',
      opacity: [0, 1],
      translateY: [-10, 0]
    });
  },

  onAnimateExiting (animator, ref) {
    anime({
      targets: ref.current,
      duration: animator.duration.enter,
      easing: 'linear',
      opacity: [1, 0]
    });
  }
};

const Item = withAnimator(itemAnimator)(ItemComponent);

// APP

function App () {
  const [activate, setActivate] = React.useState(true);
  const timeout = React.useRef();

  React.useEffect(() => {
    timeout.current = setTimeout(() => setActivate(!activate), 2000);
    return () => clearTimeout(timeout.current);
  }, [activate]);

  return (
    <Menu animator={{ activate }}>
      <Item>Menu Item 1</Item>
      <Item>Menu Item 2</Item>
      <Item>Menu Item 3</Item>
    </Menu>
  );
}

render(<App />);
`}</code></pre>
    <p>{`The menu component animations run first. When they are completed, the menu items
run.`}</p>
    <p>{`Since the menu component is not configured to have a manager, its children items
are transitioned in `}<inlineCode parentName="p">{`parallel`}</inlineCode>{`. This can be configured. See `}<a parentName="p" {...{
        "href": "/develop/animation/managers"
      }}>{`animator managers`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      